(function () {
    'use strict';

    angular
        .module('user')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('user', {
                url: '/user',
                views:{
                    navigation: {
                        templateUrl: 'navigation/navigation.tpl.html',
                        controller: 'NavigationCtrl',
                        controllerAs: 'navigation'
                    },
					alerts: {
					   templateUrl: 'alerts/alerts.tpl.html',
					   controller: 'AlertsCtrl',
					   controllerAs: 'alerts'
					},
                    main:{
                        templateUrl: 'user/user.tpl.html',
                        controller: 'UserCtrl',
                        controllerAs: 'user'
                    },
                    footer: {
                        templateUrl: 'footer/footer.tpl.html',
                        controller: 'FooterCtrl',
                        controllerAs: 'footer'
                    }
                }
            });

    }
}());